<template>
  <div id="hy">
    <touTop :headername="headerName" />
    <div class="mis">
      <div class="left">
        <sider :headername="headerName" :sideMenus="sideMenu"  @transkey="getKey"/>
      </div>
        <router-view/>
    </div>
  </div>
</template>
<script>
import sider from "@/components/sider/sider";
import touTop from "@/components/touTop/touTop";
export default {
  data() {
    return {
      headerName: "会员系统",
      sideMenu: [{ key: 0, value: "会员管理" },{ key: 1, value: "会员明细" },{ key: 2, value: "充值退款" }],
    };
  },
  //vue的钩子函数，执行在vue挂在组件前，
  mounted() {
  },
  methods: {
     getKey(key) { //获取sider值
      if(key===0){
         this.$router.push({path: '/mis/system',query: {key: key}})
      };
      if(key===1){
         this.$router.push({path: '/mis/turnover',query: {key: key}})
      };
      if(key===2){
         this.$router.push({path: '/mis/record',query: {key: key}})
      };
    },
  },
  mounted(){
  },
  components: {
    touTop,
    sider,
  },
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.mis {
  width: 100%;
  display: flex;
  height: 95.4vh;
}
.left {
  width: 17vw;
  background: rgba(46, 62, 78, 1);
}
</style>
